import React from 'react';
import { useRouter } from 'next/router';

function Home() {
  const router = useRouter();
  React.useEffect(() => {
    router?.push('/log-in');
  });

  return <div />;
}

export default Home;
